@import "src/theme";

.layout {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  background-color: #FDF8F8;
}

.container {
  flex: 1 0;
  background-color: white;
}

.container {
  flex-basis: 60%;
  padding: 30px 30px 60px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.language {
  position: absolute;
  right: 15px;
  top: 15px;
}

.footer {
  position: absolute;
  bottom: 0;

  :global(.ant-layout-footer) {
	background-color: white;
  }
}

.content {
  width: 450px;
  text-align: center;

  & > *:first-child {
	margin-bottom: 15px;
  }
}


.title {
  margin-bottom: 0;

  span {
	color: $primary-color;
  }
}

.subtitle {
  margin-bottom: 20px;
}

@media screen and (max-width: 991px) {
  .layout {
	position: relative;
  }
  .footer {
	:global(.ant-layout-footer) {
	  background-color: transparent !important;
	}
  }
  .container {
	background-color: transparent;
	flex-basis: 100%;
	padding: 15px 15px 60px 15px;
  }

  .content {
	& > div {
	  margin-top: 45px;
	}
  }

}

@media screen and (max-width: 575px) {
  .content {
	width: 100%;
  }

}
