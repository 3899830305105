.pagination {
  margin-top: 25px;

  ul {
	text-align: right;
  }
}

.text {
  font-size: 15px;
  margin-right: 10px;
}
