.loaderContainer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease-out 0.8s;
}

.hide {
  opacity: 0;
  visibility: hidden;
}
