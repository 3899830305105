.container {
  max-width: 450px;
  margin: auto;
  padding: 25px 12px;
  min-height: 100vh;

  img {
	max-width: 100%;
  }

  p {
	margin: 0;
  }
}

.logoWrapper {
  margin-bottom: 25px;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  text-align: center;
  gap: 10px;
  margin-bottom: 35px;
}

.time {
  text-align: left;
  font-size: 20px;
  color: #f5222d;
  margin: 0;
}

.answers {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  min-height: 210px;

  :global(.ant-radio-button-wrapper) {
	border-radius: 5px !important;
	border: 1px solid #d9d9d9 !important;
	height: auto !important;
	line-height: unset !important;
	padding: 7px 10px !important;

	&:before {
	  display: none !important;
	}
  }
}

.answer {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 15px;

  :global(.ant-image) {
	margin-left: 5px;
  }

  :global(.ant-radio-button-wrapper) {
	flex: 1;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}


.errorBanner {
  margin: 50px 0;
}


@keyframes heartBeat {
  0% {
	-webkit-transform: scale(1);
	transform: scale(1);
  }

  14% {
	-webkit-transform: scale(1.02);
	transform: scale(1.02);
  }

  28% {
	-webkit-transform: scale(1);
	transform: scale(1);
  }

  42% {
	-webkit-transform: scale(1.02);
	transform: scale(1.02);
  }

  70% {
	-webkit-transform: scale(1);
	transform: scale(1);
  }
}

.heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
