@import './src/theme';

.icon {
  font-size: 20px;
}

.card {
  transition: all 0.2s linear;

  &:hover {
	border-color: $primary-color;
	transform: scale(1.01);
  }
}
