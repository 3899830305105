.table {
  :global {
	.red {
	  background-color: rgba(245, 34, 35, 0.05);
	}

	.orange {
	  background-color: rgba(250, 140, 22, 0.05);
	}

	.yellow {
	  background-color: rgba(250, 219, 20, 0.05);
	}

	.green {
	  background-color: rgba(82, 196, 26, 0.05);
	}
  }
}
