@import 'src/theme';

.name {
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px 0;
}

.down {
  margin-left: 10px;

  svg {
	font-weight: 500;
	font-size: 12px !important;
  }
}


@media screen and (max-width: 575px) {
  .name {
	display: none;
  }
  .wrapper {
	padding: 5px 8px;
	background-color: transparent;
	border: none;
  }
  .avatar {
	font-size: 20px;
  }
  .down {
	display: none!important;
  }
}
