.header {
  height: 70px !important;
  padding: 0 24px 0 274px !important;
  background: white !important;
  box-shadow: 0 0 30px rgba(12, 37, 86, 0.05);
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  right: 0;
  transition: padding-left 0.2s;
}

.collapsed {
  padding: 0 24px 0 104px !important;
}

.triggerWrapper {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.trigger {
  cursor: pointer;
}

.wrapper {
  display: flex;
  height: 100%;
  align-items: center;

  & :global(.anticon) {
	vertical-align: middle !important;
	font-size: 22px !important;
  }
}

@media screen and (max-width: 1199px) {
  .header,
  .collapsed {
	padding: 0 24px !important;
  }
}
