.bg {
  flex: 1 0 40%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 991px) {
  .bg {
	display: none;
  }
}

.animate__animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* Fading entrances  */
@-webkit-keyframes fadeIn {
  from {
	opacity: 0;
  }

  to {
	opacity: 1;
  }
}

@keyframes fadeIn {
  from {
	opacity: 0;
  }

  to {
	opacity: 1;
  }
}

.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
