.wrapper {
  position: relative;
  strong {
    font-size: 36px;
    position: absolute;
    top: -30px;
    right: 50%;
    transform: translateX(160%);
    font-weight: 500;
  }
}
