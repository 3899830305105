@import "src/theme";

.sider {
  box-shadow: 0 0 30px rgba(12, 37, 86, 0.05);
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 100;

  .menu {
	max-height: calc(100vh - 100px);
  }
}

.image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
}

@media screen and (max-width: 1199px) {
  .backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99;
  }

  .sider {
	&:global(.ant-layout-sider-collapsed) {
	  left: -80px !important;
	  transform: translate(0, 0) !important;
	}
  }
}
