@import './src/theme';

.wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.button {
  position: relative;
  margin-right: 10px;
  width: 36px !important;
  height: 27px !important;

  &:last-child {
	margin-right: 0;
  }

  &:focus {
	:global(.anticon-message) {
	  position: absolute !important;
	}
  }
}

.active {
  border-color: transparent !important;

  :global(.anticon) {
	color: white !important;
  }
}
