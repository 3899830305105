.item {
  margin: 0 !important;
}

.datePicker {
  width: 200px;
}

@media screen and (max-width: 767px) {
  .item {
	display: none!important;
  }
}
