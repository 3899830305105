.block {
  width: 50px;
  height: 15px;
  border-radius: 2px;
  position: relative;
  &:before {
	content: '';
	position: absolute;
	top: -2px;
	left: -2px;
	right: -2px;
	bottom: -2px;
	border-radius: 2px;
	border:1px solid #bfbfbf;
  }
}
