.wrapper {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.item {
  margin-bottom: 20px !important;

}

.name {
  margin-bottom: 5px;
}

@media screen and (max-width: 575px) {
  .wrapper {
	flex-direction: row;
	margin-top: 10px;
  }
  .name {
	margin: 0 10px 0 0;
  }

}
