@import "src/theme";

.footer {
  text-align: right;

  span {
	color: $primary-color;
  }
}

@media screen and (max-width: 767px) {
  .footer {
	text-align: center;
  }
}
