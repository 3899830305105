
.logo {
  height: 100px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
	display: block;
	max-width: 100%;
	height: 100%;
	object-fit: contain;
  }
}
