.dropdown {
  max-width: 315px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.012),
  0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.content {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, .06);

  &:last-child {
	border-bottom: none;
  }
}


.icon {
  font-size: 20px;
}

.title {
  display: block;
  color: rgba(0, 0, 0, .70);
  cursor: default;
  margin: 0 0 10px 5px;
}
