.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  cursor: pointer;
  width: 36px !important;
  height: 27px !important;
}

.mark {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  width: 36px;
  height: 27px;
}
