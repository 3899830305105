.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 35px;
}

.variant {
  margin-bottom: 12px!important;
}
