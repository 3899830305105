.logoSmall {
  width: 100%;
  height: 70px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
	max-width: 100%;
	height: 100%;
	object-fit: contain;
  }
}
