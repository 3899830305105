.bid {
  cursor: pointer;
  position: relative;
  transition: all 0.2s linear;

  &:hover {
    box-shadow: 0 10px 30px 0 rgba(0, 60, 179, 0.1);

    .edit {
      opacity: 1;
      visibility: visible;
    }
  }

  :global(.ant-card-body) {
    padding: 0;
  }

  margin-bottom: 20px !important;
}

.content {
  padding: 10px;
}

.edit {
  position: absolute !important;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  transition: all 0.1s linear;
  opacity: 0;
  visibility: hidden;
}

.createdAt {
  font-size: 13px;

}

.bidFixed {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(0, -60%);
  font-size: 19px;
}

.bidHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.bidMain {
  margin: 5px 0 10px 0;
}

.bidFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.source {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
}

.sourceColor {
  height: 3px;
  border-radius: 5px;
  background: #222;
}

.additional {
  display: flex;
  align-items: center;
}

.enrollment {
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  border-radius: 6px 6px 0 0;
  background: #f9d9c2;
  font-weight: 500;
  text-align: center;

}
