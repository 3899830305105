.layout {
  position: relative;
  padding-top: 70px;
  padding-left: 250px;
  overflow-x: hidden;
}

.collapsed {
  padding-left: 80px;
}

.siteLayout {
  min-height: calc(100vh - 70px) !important;
}

.logout {
  margin: 0 15px;

  svg {
	margin: 0 5px -1px 0;
  }
}

.content {
  margin: 0 !important;
  padding: 32px 24px !important;
}


@media screen and (max-width: 1199px) {
  .content {
	margin: 16px;
  }
  .layout {
	padding-left: 0;
	min-height: auto !important;
  }
  .collapsed {
	padding-left: 0;
  }
}

@media screen and (max-width: 575px) {
  .content {
	margin: 0;
	border-radius: 0;
	padding: 32px 12px !important;
  }
}
