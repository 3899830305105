.result {
  padding: 42px 0 !important;
}

.paragraph {
  display: block;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0;
  font-size: 16px;
}


.title {
  margin-bottom: 40px;
}

.variants {
  padding-left: 15px;
}

.question {
  position: relative;
  display: inline-block;
}

.mark {
  position: absolute;
  right: -25px;
  top: 0;
}

.hint {
  * {
    color: black;
	margin: 0;
  }
}