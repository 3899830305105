@import "src/theme";

.length {
  background: #fafafa;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  cursor:pointer;

  span {
	background: $primary-color;
	color: #fff;
	border-radius: 2px;
	padding: 2px 4px;
	font-size: 13px;
  }
}
