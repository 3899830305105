.search {
  justify-self: flex-start;
  max-width: 300px;

}

@media screen and (max-width: 767px) {
  .search {
	width: 100%;
	max-width: 100%;
  }
}
