.activeBranch {
  display: flex;
  flex-direction: column;

  & div {
	line-height: initial !important;
	text-align: center;
  }
}


.icon:global(.anticon) {
  font-size: 16px!important;
  vertical-align: top!important;
}

.top {
  font-size: 13px;
}

.bottom {
  font-weight: 600;
}
