@import "src/theme";

.button {
  color: #cf1322 !important;
}

.confirmation {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;

  strong {
	color: $primary-color;
  }
}
