@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');
@import './src/_theme.scss';

body {
  margin: 0;
  font-family: "Nunito", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.ant-page-header {
  padding-top: 0 !important;
}

.auth-form {
  .ant-input-group-addon {
	width: 60px !important;
  }
}

.rcs-inner-handle {
  background-color: #ccc !important;
  opacity: 0.7;
}

.ant-btn-no-style {
  border: none !important;
  background: none !important;
  box-shadow: none;
  padding: 0 !important;
  cursor: pointer;
  outline: none;

  &:hover {
	color: inherit !important;
	background: none;
  }
}

.list-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.ant-descriptions-item-label {
  font-weight: 500 !important;
}

.ant-menu {
  border-right: none !important;
}

.ant-card {
  box-shadow: 0 10px 30px 0 rgba(0, 60, 179, 0.03);
}

.ant-menu li {
  margin-top: 0 !important;
}


.ant-table-cell {
  white-space: nowrap !important;
}

.ant-alert-info {
  border: 1px solid #91d5ff !important;
  background-color: #e6f7ff !important;
}

.ant-alert-info .ant-alert-icon {
  color: #1890ff !important;
}

.responsive-table .ant-table-container {
  overflow-x: auto;
}

@media screen and (max-width: 1199px) {
  .adaptive-table {
	border-radius: 5px 5px 0 0;

	.ant-table {
	  display: block;

	  &-title {
		border-bottom: 1px solid #eeeeee;
	  }

	  &-thead {
		display: none;
	  }

	  &-thead > tr,
	  &-tbody > tr {
		th,
		td {
		  &:first-of-type {
			padding-top: 1rem;
		  }

		  &:last-of-type {
			padding-bottom: 1rem;
		  }
		}

		> th,
		> td {
		  display: block;
		  width: auto !important;
		  border: none;
		  padding: 0.5rem 1rem;

		  &:last-child {
			border-bottom: 20px solid #eee;
		  }
		}
	  }
	}
  }
}

@media screen and (max-width: 767px) {
  .ant-space {
	flex-wrap: wrap !important;
  }
  .ant-page-header-heading-sub-title {
	display: none !important;
  }
}

@media screen and (max-width: 575px) {
  .ant-drawer-content-wrapper {
	width: 100% !important;
  }
  .ant-alert {
	display: flex !important;
	flex-direction: column !important;

	&-icon {
	  margin-bottom: 10px;
	}
  }
  .ant-drawer-body {
	padding: 24px !important;
  }
  .ant-card {
	&-body {
	  padding: 24px 12px !important;
	}
  }
}


.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}


.hide {
  display: none !important;
}
