@import './src/theme';

.column {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  width: 335px;
  flex-shrink: 0;
  margin-right: 40px;
  position: relative;

  &:first-child {
    .color {
      background-color: #fa8c16;
    }
  }
  &:nth-child(2) {
    .color {
      background-color: #1677ff;
    }
  }
  &:nth-child(3) {
    .color {
      background-color: #fadb14;
    }
  }
  &:nth-child(4) {
    .color {
      background-color: #a0d911;
    }
  }
  &:nth-child(5) {
    .color {
      background-color: #eb2f96;
    }
  }
  &:nth-child(6) {
    .color {
      background-color: #722ed1;
    }
  }
  &:nth-child(7) {
    .color {
      background-color: #52c41a;
    }
  }
  &:nth-child(8) {
    .color {
      background-color: #f5222d;
    }
  }
  &:last-child {
    margin-right: 0;
  }
}

.title {
  position: relative;
  margin-bottom: 0;

  &:hover {
	.buttons {
	  opacity: .7;
	  visibility: visible;
	}
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  height: 85px;
}

.color {
  margin-top: auto;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #434343;
}


@media screen and (max-width: 575px) {
  .column {
	width: 100%;
  }
}
