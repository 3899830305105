.actions {
  background: #fc333f;
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  opacity: 0.9;

}

.text {
  font-size: 13px;
  color: white!important;
}