.wrapper {
  position: absolute;
  top: 10px;
  right: 40px;
  cursor: pointer;
}

.true {
  transform: rotate(-90deg);
  transition: transform 0.2s linear;
}

.false {
  transform: rotate(90deg);
  transition: transform 0.2s linear;
}
