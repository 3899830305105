.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  & :global {
	.ant-typography {
	  margin-bottom: 0 !important;
	}
  }
}
