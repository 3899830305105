.bid {
  background: #fff;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  font-size: 14px;
  letter-spacing: 0;

  &:hover {
	background-color: lighten(#fafafa, 10);
  }
}


.divider {
  margin: 2px 0 !important;
}

.wrapper {
  width: 100%;
}

.phone {
  display: inline-block;
}

.comment {
  word-break: initial;
}

.name {
  cursor: text;
  padding-right: 25px;
  word-break: initial;
}
