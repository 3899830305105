.question {
  :global(.ant-list-item-meta-title) {
	font-size: 19px !important;
  }

  :global(.ant-list-item-meta-description) {
	margin-left: 25px !important;
  }
}

.title {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
}

.answer * {
  display: inline;

  margin-bottom: 0;
  margin-right: 5px;
}
