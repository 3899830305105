.debtor {
  background-color: rgba(255, 241, 240, 0.4) !important;

  &:hover {
	td {
	  background-color: rgba(255, 241, 240, 1) !important;
	}
  }

  td {
	border-color: rgba(255, 241, 240, 1);
  }
}

.title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  h5 {
	margin: 0 !important;
  }
}
