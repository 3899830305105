@import './src/theme';

.column {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  width: 305px;
  flex-shrink: 0;
  margin-right: 40px;
  position: relative;

  &:first-child {
	padding-left: 0;
  }
  &:last-child {
	padding-right: 0;
  }
}

.title {
  position: relative;

  &:hover {
	.buttons {
	  opacity: .7;
	  visibility: visible;
	}
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  margin-bottom: 40px;
}

.form {
  position: absolute;
  z-index: 0;
  bottom: -55px;
  left: 0;
  right: 0;
}


.droppable {
  flex: 1;
  margin-top: 5px;
  transition: background-color 0.2s linear;
  border-radius: 5px;
}

.bg {
  background-color: lighten($primary-color, 30);
}

@media screen and (max-width: 575px) {
  .column {
	width: 100%;
  }
}
