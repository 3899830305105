.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
	margin-right: 5px;
	display: flex;
	align-items: center;

	&:last-child {
	  margin-right: 0;
	}

	img {
	  margin-right: 5px;
	}
  }
}
