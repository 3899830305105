.group {
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  max-width: 230px;

  span {
	color: #222;
  }

  h4, h5 {
	color: #222;
	white-space: normal;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider {
  margin: 3px;
  height: 1px;
  background-color: #555;
}

.dates {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
