@import "src/theme";

.card {
  padding: 5px 10px;
  background: #ffd8bf;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 100%;
  word-break: break-all;
  position: relative;
}

.draggable {
  cursor: grab;
}

.title {
  margin-right: 60px;
  display: inline-block;
  cursor: text;
  position: relative;
  word-break: initial;
}

.button {
}

.bids {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
